<section class="container-fluid d-flex justify-content-center" style="background-color: rgb(21, 43, 75);">
  <div class="sessaoHeader row col-12 pt-3 pb-3">
    <div class="col-md-4 ml-md-4 pl-md-4 mt-md-5 mb-md-2 d-flex justify-content-center">
      <h4 style="color: white;">Assinatura Digital de Contrato</h4>
    </div>
    <div class="col-md-3 ml-md-3 pl-md-3 mt-md-5 mb-md-2 d-flex justify-content-center">
      <img [src]="logo" class="logo mb-md-2" alt="Logo">
    </div>
  </div>
</section>

<nb-stepper class="step-contrato" [orientation]="'horizontal'" fullWidth [disableStepNavigation]="true" #stepper
  *ngIf="permissaoCamera && permissaoGeo" [selectedIndex]="PassoStep">
  <nb-step label="Dados Pessoais">
    <form [formGroup]="form" novalidate (keydown.enter)="$event.preventDefault()">
      <section>
        <input type="hidden" formControlName="Ip" name="inputIp" [(ngModel)]="assinatura.Ip">
      </section>
      <section class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-12 m-4 mt-md-5 mb-md-1 pb-md-1 pt-md-4">
            <div class="titulo-container">
              <h4 class="titulo" style="letter-spacing: 3.2px;">DADOS PESSOAIS</h4>
              <div class="linha-titulo mb-md-1 mb-2"
                style="flex-grow: 1; background-color: rgb(237, 237, 237); height: 2px; margin-left: 10px;"></div>
            </div>
            <div class="form">
              <div class="form-row justify-content-center">
                <div class="form-group col-md-4">
                  <label for="nome" class="labels">Nome</label>
                  <input type="text" class="form-control" autocomplete="off" formControlName="Nome" name="inputNome"
                    [(ngModel)]="assinatura.Nome">
                </div>
                <div class="form-group col-md">
                  <label for="dataNascimento" class="labels">Data de Nascimento</label>
                  <input class="form-control input-xs" formControlName="DataNascimento" placeholder="dd/mm/aaaa"
                    [nbDatepicker]="dpDataNascimento" [(ngModel)]="assinatura.DataNascimento"
                    (keyup)="dateMask($event,'DataNascimento')">
                  <nb-datepicker format="DD/MM/YYYY" #dpDataNascimento
                    (dateChange)="setDataNascimento($event)"></nb-datepicker>
                </div>
                <div class="form-group col-md">
                  <label for="cpf" class="labels">CPF</label>
                  <input type="text" class="form-control" id="cpf" formControlName="Cpf" name="inputCpf"
                    [textMask]="{mask: MASKS.cpf.textMask}" [(ngModel)]="assinatura.Cpf">
                </div>
                <div class="form-group col-md">
                  <label for="rg" class="labels">RG</label>
                  <input type="text" class="form-control" id="rg" formControlName="RG" name="inputRG"
                    [(ngModel)]="assinatura.RG">
                </div>
                <div class="form-group col-md">
                  <label for="orgaoExpedidor" class="labels">Orgão Expedidor</label>
                  <input type="text" class="form-control" id="orgaoExpedidor" formControlName="SSP" name="inputSSP"
                    [(ngModel)]="assinatura.SSP">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 mt-md-1 mb-md-1 pb-md-1 pt-md-2">
            <div class="titulo-container">
              <h4 class="titulo" style="letter-spacing: 3.2px;">ENDEREÇO</h4>
              <div class="linha-titulo mb-md-1 mb-2"
                style="flex-grow: 1; background-color: rgb(237, 237, 237); height: 2px; margin-left: 10px;"></div>
            </div>
            <div class="form">
              <div class="form-row justify-content-md-start">
                <div class="form-group col-md-4">
                  <label for="cep" class="labels">CEP</label>
                  <input type="text" class="form-control" id="cep" (blur)="buscaCep()" formControlName="CEP"
                    [textMask]="{mask: MASKS.cep.textMask}" name="inputCEP" [(ngModel)]="assinatura.CEP">
                </div>
                <div class="form-group col-md">
                  <label class="labelsResidencia ml-md-2">Residência</label>
                  <div class="botoes1 ml-2">
                    <div class="form-check form-check-inline ml-md-3">
                      <input class="form-check-input" type="radio" name="TipoResidencia" id="proprio" value="Propria"
                        formControlName="TipoResidencia" [(ngModel)]="assinatura.TipoResidencia">
                      <label class="form-check-label" for="proprio">Próprio</label>
                    </div>
                    <div class="form-check form-check-inline ml-md-4 ml-3">
                      <input class="form-check-input" type="radio" name="TipoResidencia" id="alugado" value="Alugada"
                        formControlName="TipoResidencia" [(ngModel)]="assinatura.TipoResidencia">
                      <label class="form-check-label" for="alugado">Alugado</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-md-12 mt-md-1 mb-md-1 pb-md-1">
            <div class="form">
              <div class="form-row justify-content-md-start">
                <div class="form-group col-md-4">
                  <label for="cidade" class="labels">Cidade</label>
                  <input type="text" class="form-control" id="cidade" formControlName="Cidade" name="inputCidade"
                    [(ngModel)]="assinatura.Cidade">
                </div>
                <div class="form-group col-md">
                  <label for="estado" class="labels">Estado</label>
                  <input type="text" class="form-control" id="estado" formControlName="Estado" name="inputEstado"
                    [(ngModel)]="assinatura.Estado">
                </div>
                <div class="form-group col-md">
                  <label for="bairro" class="labels">Bairro</label>
                  <input type="text" class="form-control" id="bairro" formControlName="Bairro" name="inputBairro"
                    [(ngModel)]="assinatura.Bairro">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12 mt-md-1 mb-md-1 pb-md-1">
            <div class="form">
              <div class="form-row justify-content-md-start">
                <div class="form-group col-md-4">
                  <label for="rua" class="labels">Rua</label>
                  <input type="text" class="form-control" id="rua" formControlName="Rua" name="inputRua"
                    [(ngModel)]="assinatura.Rua">
                </div>
                <div class="form-group col-md">
                  <label for="numero" class="labels">Número</label>
                  <input type="number" class="form-control" id="numero" formControlName="Numero" name="inputNumero"
                    [(ngModel)]="assinatura.Numero">
                </div>
                <div class="form-group col-md-4">
                  <label for="complemento" class="labels">Complemento</label>
                  <input type="text" class="form-control" id="complemento" formControlName="Complemento"
                    name="inputComplemento" [(ngModel)]="assinatura.Complemento">
                </div>
                <div class="form-group col-md">
                  <label for="residente" class="labels">Residente desde</label>
                  <input type="text" class="form-control" id="residente" formControlName="Desde" name="inputDesde"
                    [(ngModel)]="assinatura.Desde" (input)="applyDateMask($event)" placeholder="MM/YYYY">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 mt-md-1 mt-3 mb-md-1 pb-md-1 pt-md-2 pt-2">
            <div class="titulo-container">
              <h4 class="titulo" style="letter-spacing: 3.2px;">CONTATO</h4>
              <div class="linha-titulo mb-md-1 mb-2"
                style="flex-grow: 1; background-color: rgb(237, 237, 237); height: 2px; margin-left: 10px;"></div>
            </div>
            <div class="form">
              <div class="form-row justify-content-md-start">
                <div class="form-group col-md-4">
                  <label for="email" class="labels">Email</label>
                  <input type="email" class="form-control" id="email" formControlName="Email" name="inputEmail"
                    [(ngModel)]="assinatura.Email">
                </div>
                <div class="form-group col-md-4">
                  <label for="celular" class="labels">Celular</label>
                  <input type="text" class="form-control" id="celular" formControlName="Celular" name="inputCelular"
                    [textMask]="{mask: MASKS.celular.textMask}" [(ngModel)]="assinatura.Celular">
                </div>
                <div class="form-group col-md-4">
                  <label for="telefone" class="labels">Telefone</label>
                  <input type="text" class="form-control" id="telefone" formControlName="Telefone"
                    [textMask]="{mask: MASKS.celular.textMask}" name="inputTelefone" [(ngModel)]="assinatura.Telefone">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container-fluid">
        <div class="row justify-content-center">
          <iframe *ngIf="contrato" [src]="contrato" width="100%" height="100%"></iframe>
          <button (click)="Avancar1()" *ngIf="form.valid" nbStepperNext
            class="botao d-flex justify-content-center align-items-center text-center col-md-7 col-10 mt-md-2 mt-2 mb-md-5 mb-4 pb-md-2 pb-2 pt-md-2 pt-2 rounded-pill btn"
            style="border: solid; border-color: rgb(12, 159, 237); border-radius: 15px;">
            <strong style="font-size: 20px;">AVANÇAR</strong>
          </button>
        </div>
      </section>
      <section>
        <div class="validation-errors justify-content-center">
          <div *ngIf="!form.valid && !form.pristine" class="alert alert-danger">
            {{ 'Para salvar o registro é necessário atender todos os requisitos' | translate}}:
            <ul>
              <li class="error-item" *ngFor="let erro of getFormValidationErrors(form)">
                <span *ngIf="erro.keyError == 'required'">{{ 'O Campo' | translate}} {{display[erro.key] != null ?
                  display[erro.key] : erro.key}} {{ 'é obrigatório' | translate}}</span>
                <span *ngIf="erro.keyError == 'minlength'">{{ 'O Campo' | translate}} {{ display[erro.key] != null ?
                  display[erro.key] : erro.key}} {{ 'não possui o mínimo de caracteres' | translate}}: {{
                  erro.value.actualLength }}/{{erro.value.requiredLength }}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </form>
  </nb-step>
  <nb-step label="Contrato">
    <section class="container-fluid">
      <div class="row justify-content-center">
        <iframe *ngIf="contrato" [src]="contrato" width="100%" height="100%"></iframe>
        <button (click)="Avancar3()" *ngIf="form.valid" nbStepperNext
          class="botao d-flex justify-content-center align-items-center text-center col-md-7 col-10 mt-md-2 mt-2 mb-md-5 mb-4 pb-md-2 pb-2 pt-md-2 pt-2 rounded-pill btn"
          style="border: solid; border-color: rgb(12, 159, 237); border-radius: 15px;">
          <strong style="font-size: 20px;">AVANÇAR</strong>
        </button>
      </div>
    </section>
  </nb-step>
  <nb-step label="Validação">
    <section class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-8 col-12 mt-4 mb-5 mt-md-5 mb-md-1 pb-md-1 pt-md-4">
          <div class="titulo-container">
            <h4 class="titulo" style="letter-spacing: 3.2px;">DOCUMENTOS</h4>
            <div class="linha-titulo mb-md-1 mb-2"
              style="flex-grow: 1; background-color: rgb(237, 237, 237); height: 2px; margin-left: 10px;"></div>
          </div>
          <div class="frenteVerso">
            <div class="col-md-4 col-12 mt-md-4 pl-md-0">
              <div class="texto">
                <h4 style="color: rgb(93, 91, 91);"><strong>FRENTE</strong></h4>
                <p style="color: rgb(93, 91, 91); font-size: 18px;">Adicione a imagem da frente do seu documento aberto
                </p>
              </div>
              <div>
                <div *ngIf="LoadingAlteracaoFrente" class="overlay">
                  <img width="50px" src="assets/img/loading3.gif" class="imagem">
                </div>
                <imagem-contrato label="{{'Logo Agência (Tamanho recomendado 300x300)' | translate}} "
                  [src]="assinatura.FotoComDocumento" width="150" height="120"
                  (submit)="alterarDocumentoFrente($event)">
                </imagem-contrato>
              </div>
            </div>
            <div class="col-md-4 col-12 mt-md-4 mt-5 pl-md-0">
              <div class="texto">
                <h4 style="color: rgb(93, 91, 91);"><strong>VERSO</strong></h4>
                <p style="color: rgb(93, 91, 91); font-size: 18px;">Adicione a imagem do verso do seu documento aberto
                </p>
              </div>
              <div>
                <div *ngIf="LoadingAlteracaoFrente" class="overlay">
                  <img width="50px" src="assets/img/loading3.gif" class="imagem">
                </div>
                <imagem-contrato label="{{'Logo Agência (Tamanho recomendado 300x300)' | translate}} "
                  [src]="assinatura.DocumentoVerso" width="150" height="120" (submit)="alterarDocumentoVerso($event)">
                </imagem-contrato>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-8 mt-md-4 mb-md-1 pb-md-1 pt-md-4">
          <div class="titulo-container">
            <h4 class="titulo" style="letter-spacing: 3.2px;">SELFIE COM DOCUMENTO</h4>
            <div class="linha-titulo mb-md-1 mb-2"
              style="flex-grow: 1; background-color: rgb(237, 237, 237); height: 2px; margin-left: 10px;"></div>
          </div>
          <div class="sessaoSelfie col-md-8 col-12 mt-4 mt-md-4 mb-5 pl-md-0">
            <div *ngIf="!capturedImage"
              class="selfieCirculo d-flex justify-content-md-center justify-content-center col-md-3 col-6 rounded-circle"
              style="background-color: rgb(242, 242, 242); align-items: center;">
              <img src="assets/icons/camera.png" class="selfie" alt="Câmera">
            </div>
            <div *ngIf="capturedImage" style="background-color: rgb(242, 242, 242); align-items: center;">
              <img [src]="capturedImage" class="captu red-image">
            </div>
            <div class="botaoAdicionar col-md-3 col-6 pb-md-2 pb-0 pl-md-0 pl-0 pr-md-0 pr-0">
              <a id="addPhotoButton" class="botao pl-md-4 pl-4 pr-md-4 pr-4 pt-md-2 pt-2 pb-md-2 pb-2 rounded-pill align-items-center text-center"
                data-toggle="modal" data-target="#staticBackdrop" (click)="startCamera()"
                style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
                <strong>+ Adicionar</strong>
              </a>
              <button *ngIf="photoConfirmed"
                class="botao pl-md-4 pl-4 pr-md-4 pr-4 pt-md-2 pt-2 pb-md-2 pb-2 rounded-pill align-items-center text-center" (click)="retakePhoto()"
                style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
                Tirar outra foto
              </button>
            </div>

          </div>
          <div class="alert alert-success" *ngIf="photoConfirmed" role="alert">
            Foto enviada com sucesso!
          </div>
        </div>
      </div>
    </section>

    <!-- Modal de Instruções -->
    <div class="modal fade mostrar" id="staticBackdrop" data-backdrop="static" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Selfie com Documento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="titulos col-md-12 col-12">
                  <div class="col-md-7">
                    <h5 class="mb-3" style="color: rgb(93, 91, 91);">Siga as instruções para fazer sua selfie de maneira
                      adequada</h5>
                  </div>
                  <div class="listaPhone col-md-7">
                    <ul class="pl-md-1 pl-1" style="color: rgb(93, 91, 91); list-style-type: none;">
                      <li class="pt-md-3"><i class="pr-md-2 pr-1"></i> A foto deve ser tirada por você e <strong>NÃO por
                          terceiros</strong></li>
                      <li class="pt-md-3 pt-3"><i class="fa fa-circle-check pr-md-2 pr-1"></i> Use um documento oficial
                        com foto e aberto quando permitir;</li>
                      <li class="pt-md-3 pt-3"><i class="fa fa-circle-check pr-md-2 pr-1"></i> Segure o documento um
                        pouco à frente dos ombros, sem inclinar, distorcer. Garanta que ele apareça por inteiro, e de
                        maneira que não encubra seu rosto.</li>
                      <li class="pt-md-3 pt-3"><i class="fa fa-circle-check pr-md-2 pr-1"></i> Olhe diretamente para a
                        câmera, sem sorrir ou fazer expressões faciais, sem cabelo na frente do rosto, ou acessórios no
                        rosto ou em volta dele;</li>
                      <li class="pt-md-3 pt-3"><i class="fa fa-circle-check pr-md-2 pr-1"></i> Tire a foto em um local
                        bem iluminado, sem sombras ou reflexos, em fundo neutro e liso, sem objetos ou pessoas atrás.
                        Certifique-se que a foto esteja focada.</li>
                    </ul>
                  </div>
                  <div class="col-md-5 ml-md-4 pt-3 pt-md-0">
                    <h5 style="color: rgb(93, 91, 91);">Exemplo</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="corpoModal col-md-12">
                  <div class="listaPC col-md-7">
                    <ul class="pl-md-1" style="color: rgb(93, 91, 91); list-style-type: none;">
                      <li class="pt-md-3"><i class="fa fa-circle-check pr-md-2"></i> A foto deve ser tirada por você e
                        <strong>NÃO por terceiros</strong></li>
                      <li class="pt-md-3"><i class="fa fa-circle-check pr-md-2"></i> Use um documento oficial com foto e
                        aberto quando permitir;</li>
                      <li class="pt-md-3"><i class="fa fa-circle-check pr-md-2"></i> Segure o documento um pouco à
                        frente dos ombros, sem inclinar, distorcer. Garanta que ele apareça por inteiro, e de maneira
                        que não encubra seu rosto.</li>
                      <li class="pt-md-3"><i class="fa fa-circle-check pr-md-2"></i> Olhe diretamente para a câmera, sem
                        sorrir ou fazer expressões faciais, sem cabelo na frente do rosto, ou acessórios no rosto ou em
                        volta dele;</li>
                      <li class="pt-md-3"><i class="fa fa-circle-check pr-md-2"></i> Tire a foto em um local bem
                        iluminado, sem sombras ou reflexos, em fundo neutro e liso, sem objetos ou pessoas atrás.
                        Certifique-se que a foto esteja focada.</li>
                    </ul>
                  </div>
                  <div class="d-flex justify-content-center col-md-5 ml-md-4 mt-3 mb-4">
                    <img src="assets/icons/imagemExemplo.png" class="exemplo" alt="Imagem de exemplo">
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <a class="botao pl-md-5 pl-4 pr-md-5 pr-4 pt-md-3 pt-2 pb-md-3 pb-2 rounded-pill" data-toggle="modal"
                data-target="#Selfie" (click)="startCamera(); closeInstructionsModal(); openSelfieModal();"
                style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
                <strong>Ok, entendi</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Modal de Captura -->
    <div class="modal fade" id="Selfie" tabindex="-1" aria-labelledby="selfieModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="selfieModalLabel">Selfie com Documento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: center;">
            <video #video *ngIf="!capturedImage" autoplay></video>
            <canvas #canvas style="display:none;"></canvas>
            <img [src]="capturedImage" *ngIf="capturedImage" class="captured-image-preview">
            <button *ngIf="!capturedImage"
              class="botao pl-md-5 pl-3 pr-md-5 pr-3 pt-md-3 pt-2 pb-md-3 pb-2 align-items-center text-center rounded-pill" (click)="captureImage()"
              style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px; align-items: center;">
              <strong>Capturar foto</strong>
            </button>
            <div *ngIf="capturedImage">
              <button class="botao pl-md-5 pl-3 pr-md-5 pr-3 pt-md-3 pt-2 pb-md-3 pb-2 align-items-center text-center rounded-pill"
                (click)="confirmPhoto()" data-dismiss="modal" style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
                <strong>Confirmar</strong>
              </button>
              <button class="botao pl-md-5 pl-3 pr-md-5 pr-3 pt-md-3 pt-2 pb-md-3 pb-2 align-items-center text-center rounded-pill"
                (click)="retakePhoto()" style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
                <strong>Tirar outra</strong>
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <button class="botao pl-md-5 pl-3 pr-md-5 pr-3 pt-md-3 pt-2 pb-md-3 pb-2 align-items-center text-center rounded-pill" data-dismiss="modal"
            style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
              <strong>Fechar</strong>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Previsualização -->
    <div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="previewModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="previewModalLabel">Previsualização da Foto</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: center;">
            <img [src]="photoURL" id="photoPreview">
          </div>
          <div class="modal-footer">
            <button class="botao pl-md-5 pl-3 pr-md-5 pr-3 pt-md-3 pt-2 pb-md-3 pb-2 align-items-center text-center rounded-pill"
              (click)="confirmPhoto()" style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
              <strong>Confirmar</strong>
            </button>
            <button class="botao pl-md-5 pl-3 pr-md-5 pr-3 pt-md-3 pt-2 pb-md-3 pb-2 align-items-center text-center rounded-pill"
              (click)="retakePhoto()" style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
              <strong>Tirar outra</strong>
            </button>
          </div>
        </div>
      </div>
    </div>



    <section class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-8 col-12 mt-md-4 mb-md-1 mb-3 pb-md-1 pt-md-4">
          <div class="titulo-container">
            <h4 class="titulo" style="letter-spacing: 3.2px;">ASSINATURA</h4>
            <div class="linha-titulo mb-md-1 mb-2"
              style="flex-grow: 1; background-color: rgb(237, 237, 237); height: 2px; margin-left: 10px;"></div>
          </div>
          <div class="sessaoAssinatura col-md-12 col-12 mt-md-4 mt-3 pl-md-0 pl-0 pr-0">
            <div class="caixaAssinatura col-md-5 col-12 mt-md-4 mt-2 border rounded-lg">
              <div id="signature-pad" class="signature-pad">
                <div class="signature-pad--body">
                  <canvas #sPad width="500" height="150"></canvas>
                </div>
              </div>
            </div>
            <div class="sessaoBotoes col-md-5 col-12 mt-md-3 mt-3 mb-md-4 pl-md-0 pl-0 pr-md-0 pr-0">
              <button class="botaoLimpar pl-md-4 pl-3 pr-md-4 pr-3 pt-md-2 pt-2 pb-md-2 pb-2 rounded-pill"
                (click)="limpar()" type="submit" style="border-color: rgb(12, 159, 237); border: solid; border-radius: 15px;">
                <strong>Limpar Assinatura</strong>
              </button>
              <button class="botao pl-md-4 pl-3 pr-md-4 pr-3 pt-md-2 pt-2 pb-md-2 pb-2 rounded-pill" (click)="savePNG()"
                type="submit" style="border: solid; border-color: rgb(12, 159, 237); border-radius: 15px;">
                <strong>Confirmar e enviar</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </nb-step>
  <nb-step label="Pdf do Contrato">
    <section class="container-fluid">
      <div class="row justify-content-center">
        <iframe *ngIf="contrato" [src]="contrato" width="100%" height="100%"></iframe>
      </div>
    </section>
  </nb-step>
</nb-stepper>
