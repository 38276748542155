import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { Agencia, CadastroAgenciaPortal, Cidade } from '../../model';

import { EMPRESAS, ITENSPORPAGINA,API_RELATORIOS } from '../../app.config';

@Injectable()
export class AgenciaService {

  constructor(private http: HttpClient) { }


  RelatorioAgenciaSituacao(Situacao: any, ComercialID: any, DataInicial: any, DataFinal: any): Observable<any> {
    if (Situacao != undefined || Situacao != "") {
      var form = new FormData();

      form.append("Situacao", Situacao)
      form.append("ComercialID", ComercialID)
      form.append("DataInicial", DataInicial)
      form.append("DataFinal", DataFinal)

    }
    else { alert("Situação está como Indefinida") }

    return this.http.post<any>(`${API_RELATORIOS}/api/relatorios/relatoriosituacao`, form, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  alterarMarkup(alteracao: any): Observable<any> {
    return this.http.put<any>(`${EMPRESAS}/api/alterarmarkup`, alteracao, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  buscaClienteBackoffice(codigo: any, agenciaId: number = 0) {
    if (agenciaId > 0) {
      return this.http.get<any>(`${EMPRESAS}/api/backoffice/getcliente/${codigo}/${agenciaId}`);
    }
    else {
      return this.http.get<any>(`${EMPRESAS}/api/backoffice/getcliente/${codigo}`);
    }
  }

  buscaAgentesBackoffice(codigoERP: any, cidadeId: any, codigoAgencia: any) {
    return this.http.get<any>(`${EMPRESAS}/api/backoffice/getClienteAgentes/${codigoERP}/${cidadeId}/${codigoAgencia}`);
  }

  buscarPorParametro(param: any, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');

    if (param.length > 0) {
      return this.http.get<any>(`${EMPRESAS}/api/Agencia/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {

      //return this.http.get<any>( `${EMPRESAS}/api/Agencia/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      return this.http.get<any>(`${EMPRESAS}/api/Agencia?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }

  buscarPorParametroSemPaginacao(agenciaId: number = 0): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');

    if (agenciaId > 0) {
      return this.http.get<any>(`${EMPRESAS}/api/Agencia/list/btnGrupoAgencias/${agenciaId}`, { headers: headers });
    }
    else {
      return this.http.get<any>(`${EMPRESAS}/api/Agencia/list/btnGrupoAgencias`, { headers: headers });
    }


  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${EMPRESAS}/api/Agencia/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  buscarPorIdSimplificado(id: number): Observable<any> {
    return this.http.get<any>(`${EMPRESAS}/api/Agencia/buscaporidsimplificado/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${EMPRESAS}/api/Agencia/${id}`);
  }

  salvar(agencia: Agencia): Observable<any> {
    if (agencia.Id > 0) {
      return this.http.put<any>(`${EMPRESAS}/api/Agencia/${agencia.Id}`, agencia);
    } else {
      return this.http.post<any>(`${EMPRESAS}/api/Agencia/`, agencia);
    }

  }

  uploadLogo(arquivo: any, id: number): Observable<any> {

    const url = `${EMPRESAS}/api/agencia/logo/${id}/`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, { headers: headers });
  }

  vinculaIntegrador(id: number, integradorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-integrador/vincular/${id}/${integradorId}`, {});
  }

  desvincularIntegrador(id: number, integradorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-integrador/desvincular/${id}/${integradorId}`, {});
  }

  vincularConsultor(id: number, consultorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-consultor/vincular/${id}/${consultorId}`, {});
  }

  desvincularConsultor(id: number, consultorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-consultor/desvincular/${id}/${consultorId}`, {});
  }

  aumentarLimiteCredito(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/aumentarlimite/${id}`, { valor: valor, observacao: obs });

  }

  revogarLimiteCredito(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/revogarlimite/${id}`, { valor: valor, observacao: obs });

  }

  bloquearAgencia(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/bloquear/${id}`, { valor: valor, observacao: obs });
  }

  liberarAgencia(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/liberar/${id}`, { valor: valor, observacao: obs });
  }

  reprovarAgencia(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/reprovar/${id}`, { valor: valor, observacao: obs });
  }

  salvarFormaPagamento(id: number, agenciaForma: any): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-formapgto/salvar/${id}`, agenciaForma);
  }


  buscarPagamentos(filtro, pagina): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-pagamentos/${filtro.AgenciaId}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, filtro);
  }

  salvarPagamentoFatura(pagamento): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-pagamentos`, pagamento);
  }

  buscarSaldo(id): Observable<any> {
    return this.http.get<any>(`${EMPRESAS}/api/agencia-gestao/saldo/${id}`,);
  }

  alterarsaldo(agencia: Agencia): Observable<any> {
    return this.http.put<any>(`${EMPRESAS}/api/Agencia/alterarsaldo/${agencia.Id}`, agencia);
  }

  informacoesAgenciasWB({Id, FraseHome, FraseRodape, Facebook, Instagram, WhatsApp, QuemSomos}: Agencia, LogoWBFooter: any): Observable<any>{
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();

    formData.append('LogoFooter', LogoWBFooter);
    formData.append('Dados', JSON.stringify({Id, FraseHome, FraseRodape, Facebook, Instagram, WhatsApp, QuemSomos}));

    return this.http.put<any>( `${EMPRESAS}/api/agencia/informacoesagencias/${Id}`, formData, {headers : headers});
  }




// Cadastro de Agências
criarCadastroAgencia(formData: any, quantidadeSocios: number): Observable<any> {
  // const formData = new FormData();
  // formData.append("quantidadeSocios", quantidadeSocios.toString());
  // Object.keys(CadastroAgenciaPortal).forEach(key => {
  //   if (key == "quantidadeSocios") {
  //     formData.append(key, quantidadeSocios.toString());
  //     return;
  //   }
  //   formData.append(key, CadastroAgenciaPortal[key]);
  // });

  return this.http.post<any>(`${EMPRESAS}/api/SolicitarCadastroDeAgenciaAtualizado`, formData);
}


  removerCadastroAgencia(id: Number): Observable<any> {
    return this.http.delete<any>(`${EMPRESAS}/api/CadastroAgencia/Remover/${id}`);
  }

  salvarCadastroAgencia(cadastroAgencia): Observable<any> {
    if (cadastroAgencia.Id > 0) {
      return this.http.put<any>(`${EMPRESAS}/api/CadastroAgencia/Alterar/${cadastroAgencia.Id}`, cadastroAgencia);
    } else {
      //Ainda não criado;
      return this.http.post<any>(`${EMPRESAS}/api/CadastroAgencia/Criar/`, cadastroAgencia);
    }
  }

  buscarPorParametroCadastroAgencia(param: any, pagina: Number): Observable<any> {
    const headers = new HttpHeaders();
    if (param && param.length > 0) {
      return this.http.get<any>(`${EMPRESAS}/api/CadastroAgencia/Lista?Parametro=${param ? param.trim() : ""}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${EMPRESAS}/api/CadastroAgencia/Lista?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }

  buscarPorIdCadastroAgencia(id: number): Observable<any> {
    return this.http.get<any>(`${EMPRESAS}/api/CadastroAgencia/PorId/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  consultarSPC(CNPJ: string, Id: boolean, agenciaJaCadastrada: boolean = false): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/CadastroAgencia/ConsultarSPC`, {CNPJ: CNPJ, Id: Id, AgenciaJaCadastrada: agenciaJaCadastrada});
  }

  alterarStatusCadastroAgencia(cadastroAgenciaId: number, statusAtual: number, novoStatus: number, motivoAlteracao: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/CadastroAgencia/AlterarStatus/${cadastroAgenciaId}`, {
      StatusAtual: statusAtual,
      NovoStatus: novoStatus,
      MotivoAlteracao: motivoAlteracao
    });
  }

  removerAnexo(id: Number): Observable<any> {
    return this.http.delete<any>(`${EMPRESAS}/api/AnexoCadastroAgencia/Remover/${id}`);
  }
}
