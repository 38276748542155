<section class="container col-12 bg-light">


  <section class="cadastrar box-card p-0 p-md-4">

    <div class="mt-md-3 mt-3 pt-4">
      <h1 class="titulos-box text-info text-center">CADASTRO DE AGÊNCIAS</h1>
    </div>

    <!-- <div class="col-12 my-auto px-2 py-2">
      <h2 class="heading-form" style="font-size: 12px; color: grey;">Nós respeitamos a sua privacidade.<br>Para mais
        informações sobre como tratamos os seus dados,<br><br> consulte a nossa<a style="color: #36a9e1;"
          href="privacidade.html"> Política de Privacidade</a>.</h2>
    </div> -->

    <div id="loading-gif" class="hidden">
      <div class="overlay">
        <img src="https://visme.co/blog/wp-content/uploads/2020/12/header-19.png" class="imagem">
      </div>
    </div>

    <div id="error-message" class="hidden">
      <h2 style="text-align: center; margin: 30px;">Ocorreu algum erro ao gerar sua solicitação, verifique os
        dados e
        arquivos enviados e tente novamente.</h2>
    </div>

    <div class="wrapper p-md-4 p-4 col-12">

      <form [formGroup]="form" (ngSubmit)="enviarCadastro()">

        <div class="col-12">
          <div>
            <h2 style="color: #36a9e1; padding-bottom: 20px; text-align: center;">{{"Identificação" | translate}}
            </h2>
          </div>

          <div class="form">
            <div class="inputfield">
              <label for="razaosocial">{{"Razão Social" | translate}}:</label>
              <input type="text" class="input" formControlName="razaoSocial" minlength="10" required>
              <div *ngIf="form.get('razaoSocial').invalid && form.get('razaoSocial').touched" class="text-danger">
                <small *ngIf="form.get('razaoSocial').hasError('required')">Razão Social é obrigatório.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="nomefantasia">{{"Nome Fantasia" | translate}}:</label>
              <input type="text" class="input" formControlName="nomeFantasia" maxlength="200" required>
              <div *ngIf="form.get('nomeFantasia').invalid && form.get('nomeFantasia').touched" class="text-danger">
                <small *ngIf="form.get('nomeFantasia').hasError('required')">Nome Fantasia é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="cnpj">CNPJ:</label>
              <input type="text" class="input" modelo="00.000.000/0000-00" placeholder="Exemplo: 00.000.000/0000-00"
                formControlName="cnpjAgencia" mask="00.000.000/0000-00" (change)="aplicarMascaraCNPJ()" maxlength="18"
                required>
              <div *ngIf="form.get('cnpjAgencia').invalid && form.get('cnpjAgencia').touched" class="text-danger">
                <small *ngIf="form.get('cnpjAgencia').hasError('required')">CNPJ é obrigatório.</small>
                <small *ngIf="form.get('cnpjAgencia').hasError('pattern')">Formato do CNPJ inválido.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="identificacaoestadual">{{"Inscrição Estadual" | translate}}:</label>
              <input type="text" class="input" placeholder="" formControlName="identificacaoEstadual" required>
              <div *ngIf="form.get('identificacaoEstadual').invalid && form.get('identificacaoEstadual').touched"
                class="text-danger">
                <small *ngIf="form.get('identificacaoEstadual').hasError('required')">Identificação Estadual é
                  obrigatório.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="identificacaomunicipal">{{"Inscrição Municipal" | translate}}:</label>
              <input type="text" class="input" placeholder="" formControlName="identificacaoMunicipal" required>
              <div *ngIf="form.get('identificacaoMunicipal').invalid && form.get('identificacaoMunicipal').touched"
                class="text-danger">
                <small *ngIf="form.get('identificacaoMunicipal').hasError('required')">Identificação Municipal é
                  obrigatório.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="embratur">Embratur:</label>
              <input type="text" class="input" placeholder="" formControlName="embratur" required>
              <div *ngIf="form.get('embratur').invalid && form.get('embratur').touched" class="text-danger">
                <small *ngIf="form.get('embratur').hasError('required')">Embratur é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label>{{"Idioma" | translate}}</label>
              <div class="custom_select">
                <select formControlName="idioma" required>
                  <option value="pt" selected>{{"Português" | translate}}</option>
                  <option value="en">{{"Inglês" | translate}}</option>
                  <option value="es">{{"Espanhol" | translate}}</option>
                </select>
              </div>
              <div *ngIf="form.get('idioma').invalid && form.get('idioma').touched" class="text-danger">
                <small *ngIf="form.get('idioma').hasError('required')">Idioma é obrigatório.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Contatos" | translate}}</h2>

          <div class="form">
            <div class="inputfield">
              <label for="telefoneprincipal">{{"Telefone Principal" | translate}}</label>
              <input type="text" class="input" placeholder="(99) 9999 - 9999" formControlName="telefonePrincipal"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" required>
              <div *ngIf="form.get('telefonePrincipal').invalid && form.get('telefonePrincipal').touched"
                class="text-danger">
                <small *ngIf="form.get('telefonePrincipal').hasError('required')">Telefone Principal é
                  obrigatório.</small>
                <small *ngIf="form.get('telefonePrincipal').hasError('pattern')">Telefone Principal é inválido.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="telefonesecundario">{{"Telefone Secundário" | translate}} - Opcional</label>
              <input type="text" class="input" placeholder="(99) 9999 - 9999" formControlName="telefoneSecundario"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
              <div *ngIf="form.get('telefoneSecundario').invalid && form.get('telefoneSecundario').touched"
                class="text-danger">
                <small *ngIf="form.get('telefoneSecundario').hasError('pattern')">Telefone Secundário é
                  inválido.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="emailagencia">{{"E-mail" | translate}}:</label>
              <input type="email" formControlName="emailAgencia" placeholder="Exemplo: email@email.com" class="input"
                required>
              <div *ngIf="form.get('emailAgencia').invalid && form.get('emailAgencia').touched" class="text-danger">
                <small *ngIf="form.get('emailAgencia').hasError('email')">{{"E-mail" | translate}} é
                  inválido.</small>
                <small *ngIf="form.get('emailAgencia').hasError('required')">{{"E-mail" | translate}} é
                  obrigatório</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="website">{{"Website" | translate}} - Opcional</label>
              <input type="text" class="input" placeholder="seusite.com.br" required formControlName="website" required>
              <div *ngIf="form.get('website').invalid && form.get('website').touched" class="text-danger">
                <small *ngIf="form.get('website').hasError('pattern')">{{"Website" | translate}} é inválido.</small>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Endereço da Agência" | translate}}
          </h2>

          <div class="form">
            <div class="inputfield">
              <label for="cep">{{"CEP" | translate}}:</label>
              <input type="text" class="input" maxlength="9" formControlName="cep" required>
              <div *ngIf="form.get('cep').invalid && form.get('cep').touched" class="text-danger">
                <small *ngIf="form.get('cep').hasError('required')">{{"CEP" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="logradouro">{{"Logradouro" | translate}}:</label>
              <input type="text" class="input" formControlName="logradouro" required>
              <div *ngIf="form.get('logradouro').invalid && form.get('logradouro').touched" class="text-danger">
                <small *ngIf="form.get('logradouro').hasError('required')">{{"Logradouro" | translate}}é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="numero">{{"Número" | translate}}:</label>
              <input type="text" class="input" formControlName="numero" maxlength="6" required>
              <div *ngIf="form.get('numero').invalid && form.get('numero').touched" class="text-danger">
                <small *ngIf="form.get('numero').hasError('required')">{{"Número" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="bairro">{{"Bairro" | translate}}:</label>
              <input type="text" class="input" formControlName="bairro" required>
              <div *ngIf="form.get('bairro').invalid && form.get('bairro').touched" class="text-danger">
                <small *ngIf="form.get('bairro').hasError('required')">{{"Bairro" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="cidade">{{"Cidade" | translate}}:</label>
              <input type="text" class="input" formControlName="cidade" required>
              <div *ngIf="form.get('cidade').invalid && form.get('cidade').touched" class="text-danger">
                <small *ngIf="form.get('cidade').hasError('required')">{{"Cidade" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="estado">{{"Estado" | translate}}:</label>
              <input type="text" class="input" formControlName="estado" required>
              <div *ngIf="form.get('estado').invalid && form.get('estado').touched" class="text-danger">
                <small *ngIf="form.get('estado').hasError('required')">{{"Estado" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="complemento">{{"Complemento" | translate}}:</label>
              <input type="text" class="input" formControlName="complemento" required>
              <div *ngIf="form.get('complemento').invalid && form.get('complemento').touched" class="text-danger">
                <small *ngIf="form.get('complemento').hasError('required')">{{"Complemento" | translate}} é obrigatório.</small>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Dados bancários" | translate}}
          </h2>

          <div class="form">
            <div class="inputfield">
              <label for="nomebanco">{{"Nome do Banco" | translate}}:</label>
              <input type="text" class="input" formControlName="nomeBanco" required>
              <div *ngIf="form.get('nomeBanco').invalid && form.get('nomeBanco').touched" class="text-danger">
                <small *ngIf="form.get('nomeBanco').hasError('required')">{{"Nome do Banco" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="numerobanco">{{"Número do Banco" | translate}}:</label>
              <input type="text" class="input" placeholder=""
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" formControlName="numeroBanco" required>
              <div *ngIf="form.get('numeroBanco').invalid && form.get('numeroBanco').touched" class="text-danger">
                <small *ngIf="form.get('numeroBanco').hasError('required')">{{"Número do Banco" | translate}} é obrigatório.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="agenciabanco">{{"Agência" | translate}}:</label>
              <input type="text" class="input" placeholder=""
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" formControlName="agenciaBanco"
                required>
              <div *ngIf="form.get('agenciaBanco').invalid && form.get('agenciaBanco').touched" class="text-danger">
                <small *ngIf="form.get('agenciaBanco').hasError('required')">Agência do Banco é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="numeroconta">{{"Número da Conta" | translate}}:</label>
              <input type="text" class="input" placeholder=""
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" formControlName="numeroConta"
                required><br>
              <div *ngIf="form.get('numeroConta').invalid && form.get('numeroConta').touched" class="text-danger">
                <small *ngIf="form.get('numeroConta').hasError('required')">{{"Número da Conta" | translate}} é obrigatório.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Documentos Da Agência" | translate}}</h2>

          <div class="form">
            <h1 style="text-align: center; width: 100%; margin-bottom: 15px;">{{"Contrato" | translate}}
            </h1>
            <div class="inputfield">
              <label for="contratosocial">{{"Social / Microempreendedor" | translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'contratoSocial')" style="text-align: center;" required>
              <div *ngIf="form.get('contratoSocial').invalid && form.get('contratoSocial').touched" class="text-danger">
                <small *ngIf="form.get('contratoSocial').hasError('required')">Contrato Social é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="cartaocpj">{{"Cartão CNPJ" | translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'cartaoCPJ')" id="cartaocpj">
              <div *ngIf="form.get('cartaoCPJ').invalid && form.get('cartaoCPJ').touched" class="text-danger">
                <small *ngIf="form.get('cartaoCPJ').hasError('required')">{{"Cartão CNPJ" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="comprovanteenderecocomercial">{{"Comprovante de Endereço Comercial - Dos ultimos 90 dias." | translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'comprovanteEndereco')" id="comprovanteEndereco">
              <div *ngIf="form.get('comprovanteEndereco').invalid && form.get('comprovanteEndereco').touched"
                class="text-danger">
                <small *ngIf="form.get('comprovanteEndereco').hasError('required')">Comprovante de Endereço é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="identificacaobancario">{{"Documento do Banco Identificando a Conta Bancária" | translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'identificacaoBancaria')" id="identificacaobancario">
              <div *ngIf="form.get('identificacaoBancaria').invalid && form.get('identificacaoBancaria').touched"
                class="text-danger">
                <small *ngIf="form.get('identificacaoBancaria').hasError('required')">{{"Documento do Banco Identificando a Conta Bancária" | translate}} é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="doccadastur">{{"Certificado Cadastur" | translate}}</label>
              <input type="file" class="input" (change)="fileInput($event, 'doccadastur')"
                (ngModelOptions)="{standalone: true}" id="doccadastur">
              <div *ngIf="form.get('doccadastur').invalid && form.get('doccadastur').touched" class="text-danger">
                <small *ngIf="form.get('doccadastur').hasError('required')">{{"Certificado Cadastur" | translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="docembratur">{{"Certificado Embratur" | translate}}</label>
              <input type="file" class="input" (change)="fileInput($event, 'docembratur')"
                (ngModelOptions)="{standalone: true}" id="docembratur">
              <div *ngIf="form.get('docembratur').invalid && form.get('docembratur').touched" class="text-danger">
                <small *ngIf="form.get('docembratur').hasError('required')">{{"Certificado Embratur" | translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="logoempresa">{{"Logo da Empresa (PNG OU JPG)" | translate}}</label>
              <input type="file" (change)="fileInput($event, 'logoempresa')" (ngModelOptions)="{standalone: true}"
                accept="image/*" class="input" id="logoempresa" accept="image/*">
              <div *ngIf="form.get('logoempresa').invalid && form.get('logoempresa').touched" class="text-danger">
                <small *ngIf="form.get('logoempresa').hasError('required')">{{"Logo da Empresa (PNG OU JPG)" | translate}} é
                  obrigatório.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h1 style="color: #36a9e1; font-size: 25px; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Informações dos Sócios" | translate}}</h1>

          <div class="form">
            <h2 style="text-align: center; width: 100%; margin-bottom: 10px;">{{"Quantidade de Sócios" | translate}}</h2>
            <div class="inputfield d-flex d-md-block flex-column">
              <input type="number" class="input" [(ngModel)]="numeroSocios" [ngModelOptions]="{standalone: true}"
                min="0" max="3" maxlength="1" [disabled]="socioCadastroAgencia.length > 0 ? true : false">
              <button class="btn" *ngIf="socioCadastroAgencia.length == 0" (click)="confirmaQtdeSocios()"
                style="margin-bottom: 10px;">{{"Confirmar Número de Sócios" | translate}}</button>

              <button class="btn" *ngIf="socioCadastroAgencia.length > 0" (click)="limpaNumSocios()"
                style="margin-bottom: 10px; margin-left: 10px;">{{"Mudar Quantidade de Sócios" | translate}}</button>
            </div>


            <div id="sociosdiv" *ngFor="let socio of socioCadastroAgencia; let i = index;">
              <div id="socio" style="margin-bottom: 15px">
                <h4 style="color: #36a9e1; padding-bottom: 10px; padding-top: 10px; text-align: center;">
                  Sócio - {{i + 1}}</h4>
                <div class="inputfield">
                  <label for="nomesocio">{{"Nome Completo" | translate}}</label>
                  <input type="text" class="input" id="nomesocio" [(ngModel)]="socio.nome"
                    [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.nome" class="text-danger">
                    <small>{{"Nome Completo" | translate}} é obrigatório.</small>
                  </div>
                </div>
                <div class="inputfield">
                  <label for="cpfsocio">{{"CPF" | translate}}</label>
                  <input type="text" class="input" id="cpfsocio" maxlength="14" [(ngModel)]="socio.cpf"
                    (ngModelChange)="aplicarMascaraCPF(socio)" [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.cpf" class="text-danger">
                    <small>{{"CPF" | translate}} é obrigatório.</small>
                  </div>
                </div>
                <div class="inputfield">
                  <label for="rgsocio">{{"RG" | translate}}</label>
                  <input type="text" class="input" id="rgsocio" maxlength="12" [(ngModel)]="socio.rg"
                    (ngModelChange)="aplicarMascaraRG(socio)" [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.cpf" class="text-danger">
                    <small>{{"RG" | translate}} é obrigatório.</small>
                  </div>
                </div>
                <div class="inputfield">
                  <label for="telefonesocio">{{"Telefone" | translate}}</label>
                  <input type="text" class="input" id="telefonesocio" maxlength="15" [(ngModel)]="socio.telefone"
                    (ngModelChange)="aplicarMascaraTelefoneSocios()" [ngModelOptions]="{standalone: true}">
                  <div *ngIf="mostrarMensagemErro && !socio.telefone" class="text-danger">
                    <small>{{"Telefone" | translate}} é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="emailsocio">E-mail</label>
                  <input type="text" class="input" id="emailsocio" [(ngModel)]="socio.email"
                    [ngModelOptions]="{standalone: true}">
                  <div *ngIf="mostrarMensagemErro && !socio.email" class="text-danger">
                    <small>E-mail é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="enderecosocio">{{"Endereço" | translate}}</label>
                  <input type="text" class="input" id="enderecosocio" [(ngModel)]="socio.endereco"
                    [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.endereco" class="text-danger">
                    <small>{{"Endereço" | translate}} é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="rgcnhSocio{{ i }}">{{"RG/CNH" | translate}}</label>
                  <input type="file" class="input" (change)="fileInput($event, 'rgcnh', socio)" required>
                  <div *ngIf="mostrarMensagemErro && !socio.rgcnh" class="text-danger">
                    <small>{{"RG/CNH" | translate}} é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="comprovanteResidenciaSocio{{ i }}">{{"Comprovante de Residência do sócio" | translate}}</label>
                  <input type="file" class="input" (change)="fileInput($event, 'comprovanteResidencia', socio)"
                    required>
                  <div *ngIf="mostrarMensagemErro && !socio.comprovanteResidencia" class="text-danger">
                    <small>{{"Comprovante de Residência do sócio" | translate}} é obrigatório.</small>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>


        <div class="mt-3 input-field d-flex flex-column jusitfy-content-center">
          <button class="btn btn-info text-white text-center w-100" style="font-size: 18px;"
            (click)="enviarCadastro()">{{"Enviar Cadastro" | translate}}</button>

          <div *ngIf="mostrarMensagemErro" class="erro-mensagem">
            Não foi possível cadastrar a agência por ter um ou mais campos preenchidos incorretamente.
          </div>

        </div>
      </form>
    </div>



  </section>
</section>